//This is the APp.js file
import "./globals.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumbs from './components/Breadcrumbs.js'; // Ensure you have this component set up
import Footer from './components/Footer.js';  // Adjust the path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import { CompareSurfboardsProvider } from './contexts/CompareSurfboardsContext'
import { useCompareSurfboards } from './contexts/CompareSurfboardsContext';  // Adjust the path as necessary
import AppRoutes from './routes/AppRoutes'; // Import the routes


import React, { useState, useLayoutEffect, Suspense } from 'react';
import { Link, BrowserRouter as Router, usePathname, useSearchParams, useLocation } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet';

//const inter = Inter({ subsets: ["latin"] });
const toolsItems = [
  { key: '1', title: 'Compare surfboards', href: 'compare' }
];

// Assuming wave size is measured in feet and we're interested in waves from 1 to 12 feet
const waveSizeRange = {
  min: 1,
  max: 12,
};

const surfboardMenuItemsComplete = [
  { key: 'all', title: 'All Surfboards', href: '/search?category=all' },
  { key: 'brand', title: 'By Brand', href: '/search?category=brand' },
  { key: 'waveSize', title: 'By Wave Size', href: '/search?category=waveSize' },
  { key: 'type', title: 'By Surfer ability', href: '/search?category=surfer' },
  { key: 'type', title: 'By Surfboard Type', href: '/search?category=type' },
  { key: 'waveType', title: 'By Wave Type', href: '/search?category=waveType' },

];

const surfboardMenuItems = [
  { key: 'brand', title: 'By Brand', href: '/search?category=brand' },
  { key: 'waveSize', title: 'By Wave Size', href: '/search?category=waveSize' }
];

const brandMenuItems = [
  { key: 'js-industries', title: 'JS Industries', href: 'search?brand=JS%20Industries', imageUrl: '/img/JS.webp' },
  { key: 'firewire', title: 'Firewire', href: 'search?brand=Firewire', imageUrl: '/img/firewire.png' },
  { key: 'channel-island', title: 'Channel Island', href: 'search?brand=Channel%20Island', imageUrl: '/img/channel-islands.png' },
  // Add more brands as needed
];


const sizeMenuItems = [
  { key: 'Knee', title: 'Knee (0 to 2ft)', href: '/search?size=knee' },
  { key: 'Waist', title: 'Wasit (2 to 4ft)', href: '/search?size=waist' },
  { key: 'Shoulder', title: 'Shoulder (4 to 6ft)', href: '/search?size=shoulder' },
  { key: 'Overhead+', title: 'Overhead+ (6ft+)', href: '/search?size=Overhead' },
  // Add more brands as needed
];

const SizeSubMenu = ({ items }) => {
  return (
    <ListGroup variant="flush">
      {items.map((item) => (
        <Link to={`/main${item.href}`} key={item.key} passHref>
          <ListGroup.Item action as="a" style={{ display: 'flex', alignItems: 'center' }}>
            {item.title}
          </ListGroup.Item>
        </Link>
      ))}
    </ListGroup>
  );
};


const BrandSubMenu = ({ items }) => {
  return (
    <ListGroup variant="flush">
      {items.map((item) => (
        <Link to={`/${item.href}`} key={item.key} passHref>
          <ListGroup.Item action style={{ display: 'flex', alignItems: 'center' }}>
            {item.imageUrl && <img src={item.imageUrl} alt={item.title} style={{ width: 'auto', height: '20px', marginRight: '10px' }} />}
            {item.title}
          </ListGroup.Item>
        </Link>
      ))}
    </ListGroup>
  );
};

const SurfboardToolsMenu = () => {
  const { comparedSurfboards } = useCompareSurfboards();
  console.log(comparedSurfboards);

  return (
    <ToolsMenu items={toolsItems} surfboards={comparedSurfboards} />
  );
}

const DynamicMenu = ({ items, brandItems, sizeItems }) => {

  const location = useLocation();
  const pathname = location.pathname;
  const [showSizeMenu, setShowSizeMenu] = useState(false);
  const [showBrandMenu, setShowBrandMenu] = useState(false);

  // New hooks to get the current pathname and search params
  //const pathname = usePathname();
  const searchParams = new URLSearchParams(window.location.search);
  //console.log(searchParams, typeof searchParams);

  const initialBrandVisibility = determineInitialVisibility(pathname, searchParams, brandItems, sizeItems);

  //const [showBrandMenu, setShowBrandMenu] = useState(initialBrandVisibility);

  // A function to determine initial visibility (you may need to adjust this logic based on your URL structure and requirements)
  function determineInitialVisibility(pathname, searchParams, brandItems, sizeItems) {
    const brandQueryParam = searchParams.get('brand');
    return brandItems.some(item => decodeURIComponent(item.href).includes(`brand=${brandQueryParam}`));
  }

  /*useLayoutEffect(() => {
    const brandQueryParam = searchParams.get('brand');
    const shouldShowBrandMenu = brandItems.some(item => decodeURIComponent(item.href).includes(`brand=${brandQueryParam}`));
    setShowBrandMenu(shouldShowBrandMenu);
  }, [pathname, searchParams, brandItems, sizeItems]);
*/

/*
useLayoutEffect(() => {
  const brandQueryParam = searchParams.get('brand');
  const shouldShowBrandMenu = brandItems.some(item => decodeURIComponent(item.href).includes(`brand=${brandQueryParam}`));
  console.log("Brand Query Param:", brandQueryParam);
  console.log("Should Show Brand Menu:", shouldShowBrandMenu);
  setShowBrandMenu(shouldShowBrandMenu);
}, [pathname, searchParams, brandItems]);
*/

  return (    
    <div className="side-menu" style={{ maxWidth: '300px' }}>
        <Helmet>
        <title>The Ultimate Surfboards Comparator</title>
        <meta name="description" content="The Ultimate Surfboards Comparator" />
      </Helmet>
      <Card>
        <Card.Header as="h5" className="text-center">
          Surfboards
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item >
            <Link to="/search?category=all" className='menuFirstItem'> All Surfboards </Link>
          </ListGroup.Item>
          {items.map((item) => {
            if (item.key === 'brand') {
              return (
                <React.Fragment key={item.key}>
                  <ListGroup.Item action onClick={() => setShowBrandMenu(!showBrandMenu)} style={{ cursor: 'pointer' }}>
                    {item.title}
                  </ListGroup.Item>
                  {showBrandMenu && <BrandSubMenu items={brandItems} />}
                </React.Fragment>
              );
            } else if (item.key === 'waveSize') {
              return (
                <React.Fragment key={item.key}>
                  <ListGroup.Item action onClick={() => setShowSizeMenu(!showSizeMenu)} style={{ cursor: 'pointer' }}>
                    {item.title}
                  </ListGroup.Item>
                  {showSizeMenu && <SizeSubMenu items={sizeItems} />}
                </React.Fragment>
              );
            }
            return (
              <ListGroup.Item action href={`/main${item.href}`} key={item.key}>
                {item.title}
              </ListGroup.Item>
            );
          })}
        </ListGroup>

      </Card>
    </div>
  );
};

const DynamicMenuWrapper = () => {
  return (
    <Suspense fallback={<div>Loading menu...</div>}>
      <DynamicMenu
        items={surfboardMenuItems}
        brandItems={brandMenuItems}
        sizeItems={sizeMenuItems}
      />
    </Suspense>
  );
};



const breadcrumbItems = [
  { title: 'Home', href: '/' },
  // Add more breadcrumb items as needed
];

const ToolsMenu = ({ items, surfboards }) => {
  const { removeSurfboardFromCompare } = useCompareSurfboards();

  return (
    <div className="side-menu" style={{ maxWidth: '300px', marginTop: '20px' }}>
      <Card>
        <Card.Header as="h5" className="text-center">
          The Comparator
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <span className="menuFirstItem">Surfboards to compare:</span>
          </ListGroup.Item>
          {surfboards && surfboards.map(surfboard => (
            <ListGroup.Item action as="div" key={surfboard.id}>
              <div className="d-flex justify-content-between align-items-center">
                {surfboard.surfboardName}
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => removeSurfboardFromCompare(surfboard.id)}
                >
                  Remove
                </button>
              </div>
            </ListGroup.Item>
          ))}
          {Array.isArray(surfboards) && surfboards.length > 1 && (
            <ListGroup.Item>
              <Link to="/compare">
                <button className="btn btn-success btn-sm">
                  Compare
                </button>
              </Link>
            </ListGroup.Item>
          )}
          {items.filter(item => item.key !== '1').map((item) => (
            <ListGroup.Item action href={item.href} key={item.key}>
              {item.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
    </div>
  );
};


function AppContent() {
  const location = useLocation();

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col xs={1}>
          <a href="/">
            <img id="logo" src="\img\logo.png" alt="logo of a surfboard with a tick" />
          </a>
        </Col>
        <Col xs={9}>
          <h1 className="title">The Ultimate Surfboards Comparator</h1>
        </Col>
        <Col xs={2}>
          <a href="/admin">Admin</a>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Breadcrumbs items={breadcrumbItems} />
        </Col>
      </Row>

      <Container fluid className="text-black bg-white">
        <Row>
          <Col xs={12} md={2}>
            <DynamicMenuWrapper />
            <SurfboardToolsMenu />
          </Col>
          <Col xs={12} md={10} className="d-flex align-items-center justify-content-center">
            {location.pathname === '/' && (
              <div className="intro-container text-center vh-100 d-flex flex-column justify-content-center align-items-center">
                <div className="top-section mb-3">
                  <div className="custom-image-container d-flex justify-content-center align-items-center">
                    <img src="/img/logo.png" alt="Your perfect wave companion" width={700} height={700} />
                  </div>
                </div>
                <div className="text-and-button-container mt-3">
                  <p className="intro-text mb-2">Discover your perfect wave companion.</p>
                  <button onClick={() => window.location.href = '/search?category=none'}>Start the Search</button>
                </div>
              </div>
            )}
            <AppRoutes />
          </Col>
        </Row>
      </Container>

      <Footer />
    </Container>
  );
}

function App() {
  return (
    <CompareSurfboardsProvider>
      <Router>
        <AppContent />
      </Router>
    </CompareSurfboardsProvider>
  );
}

export default App;
