import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Accordion, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase-config'; // Adjust path if necessary
import { Helmet } from 'react-helmet';

const SurfboardDisplay = () => {
  const { surfboardId } = useParams();
  const [surfboardDetails, setSurfboardDetails] = useState({
    imageUrls: [],
    surfboardName: '',
    officialPage: '',
    brandName: '',
    shaper: '',
    surfboardType: {},
    waveSizeRange: { min: '1', max: '6' },
    waveType: {},
    surferTypes: {},
    technologyDescription: '',
    surfboardDescription: '',
    surfboardReviewSynthesis: '',
    mediaLinks: { officialPresentation: [], review: [], battle: [] },
  });
  const [currentPresentationIndex, setCurrentPresentationIndex] = useState(0);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [synthesisExpanded, setSynthesisExpanded] = useState(false);
  const [reviewExpanded, setReviewExpanded] = useState(false);

  console.log("Surfboard ID:", surfboardId); // Add this line to check the surfboardId

  useEffect(() => {

    console.log("Running useEffect"); // To check if useEffect runs at all

    if (!surfboardId) {
      console.log("Surfboard ID is missing");
      return;
    }

    const fetchSurfboardData = async () => {
      try {
        const docRef = doc(db, "surfboards", surfboardId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setSurfboardDetails(prevState => ({
            ...prevState,
            ...data,
            waveSizeRange: data.waveSizeRange || { min: '1', max: '6' },
            mediaLinks: {
              officialPresentation: data.mediaLinks?.officialPresentation || [],
              review: data.mediaLinks?.review || [],
              battle: data.mediaLinks?.battle || [],
            }
          }));
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching surfboard data:", error);
      }
    };


    if (surfboardId) {
      fetchSurfboardData();
    }
  }, [surfboardId]);

  const getVideoSrc = (videoUrl) => {
    if (typeof videoUrl !== 'string') return '';
    const youtubeMatch = videoUrl.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (youtubeMatch) return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    const vimeoMatch = videoUrl.match(/(?:vimeo\.com\/(?!video\/)\d+|player\.vimeo\.com\/video\/(\d+))/);
    if (vimeoMatch) return `https://player.vimeo.com/video/${vimeoMatch[1] || vimeoMatch[2]}`;
    return 'https://www.youtube.com/embed/to96SZiBRto'; // Fallback video
  };

  const toggleExpand = setter => setter(expanded => !expanded);

  return (
    <main>
      <Container>
        <Helmet>
          <title>The Ultimate Surfboards Comparator: {surfboardDetails.surfboardName}</title>
          <meta name="description" content={surfboardDetails.surfboardDescription} />
        </Helmet>
        <Row>
          <Col xs={12} md={8}>
            <h2>{surfboardDetails.surfboardName}</h2>
            <ImageCarousel images={surfboardDetails.imageUrls} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex} />
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Description</Accordion.Header>
                <Accordion.Body style={{ whiteSpace: 'pre-line' }}>
                  {descriptionExpanded ? surfboardDetails.surfboardDescription :
                    `${surfboardDetails.surfboardDescription.substring(0, 250)}${surfboardDetails.surfboardDescription.length > 250 ? '...' : ''}`}
                  {surfboardDetails.surfboardDescription.length > 250 && (
                    <Button variant="link" onClick={() => setDescriptionExpanded(!descriptionExpanded)}>
                      {descriptionExpanded ? 'Read Less' : 'Read More'}
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>

              
            </Accordion>
            
            <h3>Official Videos</h3>
{surfboardDetails.mediaLinks.officialPresentation.length > 0 ? (
  <div className="video-section">
    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
      <Button 
        variant="secondary" 
        onClick={() => setCurrentPresentationIndex((currentPresentationIndex - 1 + surfboardDetails.mediaLinks.officialPresentation.length) % surfboardDetails.mediaLinks.officialPresentation.length)}
      >
        Previous
      </Button>
      <span>{`Video ${currentPresentationIndex + 1} of ${surfboardDetails.mediaLinks.officialPresentation.length}`}</span>
      <Button 
        variant="secondary" 
        onClick={() => setCurrentPresentationIndex((currentPresentationIndex + 1) % surfboardDetails.mediaLinks.officialPresentation.length)}
      >
        Next
      </Button>
    </div>
    <div className="review-content w-100">
      <iframe
        width="100%"
        height="315"
        src={getVideoSrc(surfboardDetails.mediaLinks.officialPresentation[currentPresentationIndex]?.url)}
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </div>
) : (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '315px', width: '100%', backgroundColor: '#f0f0f0', marginTop: '10px' }}>
    <span>No video yet</span>
  </div>
)}


                


            
          </Col>
          <Col xs={12} md={4} style={{ marginTop: '20px' }}>
            <MoreInfoCard details={surfboardDetails} />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

const ImageCarousel = ({ images, currentImageIndex, setCurrentImageIndex }) => {
  if (!images.length) return null;
  const nextImage = () => setCurrentImageIndex((currentImageIndex + 1) % images.length);
  const previousImage = () => setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  return (
    <div className="d-flex justify-content-between align-items-center my-2">
      <Button variant="secondary" onClick={previousImage}>Prev</Button>
      <img src={images[currentImageIndex]} alt="Surfboard display" style={{ maxWidth: "75%", maxHeight: "500px" }} />
      <Button variant="secondary" onClick={nextImage}>Next</Button>
    </div>
  );
};

const VideoPresentation = ({ title, videos, currentIndex, setCurrentIndex, getVideoSrc }) => {
  const nextVideo = () => setCurrentIndex((currentIndex + 1) % videos.length);
  const previousVideo = () => setCurrentIndex((currentIndex - 1 + videos.length) % videos.length);

  return (
    <div>
      <h3>{title}</h3>
      <iframe width="560" height="315" src={getVideoSrc(videos[currentIndex])} title="video" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      {videos.length > 1 && (
        <>
          <Button onClick={previousVideo}>Prev</Button>
          <Button onClick={nextVideo}>Next</Button>
        </>
      )}
    </div>
  );
};

const MoreInfoCard = ({ details }) => (
  <Card>
    <Card.Header>More about the board</Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item>Brand: {details.brandName}</ListGroup.Item>
      <ListGroup.Item>Shaper: {details.shaper}</ListGroup.Item>
      <ListGroup.Item>Official page: <a href={details.officialPage} target="_blank" rel="noopener noreferrer">{details.surfboardName}</a></ListGroup.Item>
      <ListGroup.Item>Wave size: {details.waveSizeRange.min}ft to {details.waveSizeRange.max}ft</ListGroup.Item>
      <ListGroup.Item>Surfboard Type: {Object.keys(details.surfboardType).join(', ')}</ListGroup.Item>
      <ListGroup.Item>Wave Type: {Object.keys(details.waveType).join(', ')}</ListGroup.Item>
      <ListGroup.Item>Surfer Types: {Object.keys(details.surferTypes).join(', ')}</ListGroup.Item>
      <ListGroup.Item>Technology: {details.technologyDescription}</ListGroup.Item>
    </ListGroup>
  </Card>
);

const AccordionItem = ({ eventKey, title, content, expanded, toggleExpand }) => (
  <Card>
    <Card.Header onClick={toggleExpand}>
      <h5 className="mb-0">
        {title}
      </h5>
    </Card.Header>
    <Accordion.Collapse eventKey={eventKey}>
      <Card.Body>
        {content}
      </Card.Body>
    </Accordion.Collapse>
  </Card>
);

export default SurfboardDisplay;
