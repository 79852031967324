import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth"; // Import the getAuth method for Firebase Authentication
// Import other Firebase services you might use

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhnD1bVpkuWRszIfbaNV5YDiPv6hH_LAw",
  authDomain: "surfboardpicker-a61a6.firebaseapp.com",
  projectId: "surfboardpicker-a61a6",
  storageBucket: "surfboardpicker-a61a6.appspot.com",
  messagingSenderId: "385912847997",
  appId: "1:385912847997:web:05936d42a5b94eb42cfb14",
  measurementId: "G-Z5PC7THYY6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const storage = getStorage(app); // Initialize storage with the Firebase app

// Initialize Authentication (only if using it in your app)
const auth = getAuth(app); // Retrieve the Auth instance for authentication operations

export { db, storage, auth, app };