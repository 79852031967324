// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <Container fluid className="bg-light mt-5">
      <Row>
        <Col className="text-center py-3">
          © {new Date().getFullYear()} The Ultimate Surfboards Comparator
        </Col>
        <Col className="text-center py-3">
          <Link to="/main/disclaimer">
            Disclaimer
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
