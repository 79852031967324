//this is SearchPage.js displaying surfboards that can be compare using the comparator (displayed in App.js but using the CompareSurfboardsContext)

import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase-config'; // Adjust path if necessary
import { Card, Container, Row, Col, Collapse } from 'react-bootstrap';
import { useCompareSurfboards } from '../contexts/CompareSurfboardsContext'; // Adjust path if necessary
import { Link } from 'react-router-dom';

const sizeRanges = {
    all: { min: 0, max: Infinity },
    knee: { min: 0, max: 2 },
    waist: { min: 2, max: 4 },
    shoulder: { min: 4, max: 6 },
    overhead: { min: 6, max: Infinity },
};

const SearchSurfboards = () => {
    const [filters, setFilters] = useState({ brand: 'All Brands', size: 'all' });
    const [searchResults, setSearchResults] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const { comparedSurfboards, addSurfboardToCompare, removeSurfboardFromCompare } = useCompareSurfboards();
    const brands = ["All Brands", "Channel Island", "JS Industries", "Firewire"];

    useEffect(() => {
        const fetchData = async () => {
            try {
                let queryRef = collection(db, 'surfboards');

                if (filters.brand !== "All Brands") {
                    queryRef = query(queryRef, where('brandName', '==', filters.brand));
                }

                const querySnapshot = await getDocs(queryRef);
                let results = querySnapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));

                if (filters.size !== 'all') {
                    results = results.filter(({ waveSizeRange }) =>
                        waveSizeRange &&
                        waveSizeRange.min <= sizeRanges[filters.size].max &&
                        waveSizeRange.max >= sizeRanges[filters.size].min
                    );
                }

                setSearchResults(results);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [filters]);

    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    return (
        <Container>
            <Row>
                <Col>
                    <button className="mb-3" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
                    </button>
                    <Collapse in={isFilterOpen}>
                        <div>
                            <select
                                value={filters.brand}
                                onChange={(e) => handleFilterChange('brand', e.target.value)}
                            >
                                {brands.map(brand => (
                                    <option key={brand} value={brand}>{brand}</option>
                                ))}
                            </select>
                            <select
                                value={filters.size}
                                onChange={(e) => handleFilterChange('size', e.target.value)}
                            >
                                {Object.keys(sizeRanges).map(sizeKey => (
                                    <option key={sizeKey} value={sizeKey}>{sizeKey}</option>
                                ))}
                            </select>
                        </div>
                    </Collapse>
                </Col>
            </Row>
            <Row xs={1} md={2} lg={4} className="g-4 mt-3">
                {searchResults.map((surfboard, index) => (
                    <Col key={index}>
                        <Link to={`/surfboards/${surfboard.key}`}>
                            <Card className="h-100 custom-card">
                                <Card.Img variant="top" src={surfboard.imageUrls?.[0] || "/img/HPS.jfif"} style={{ objectFit: 'contain', width: '100%', height: '300px' }} />
                                <Card.Body>
                                    <Card.Title>{surfboard.surfboardName || 'Unknown name'}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>
                        <input
                            type="checkbox"
                            checked={comparedSurfboards.some(s => s.id === surfboard.key)}
                            onChange={(e) => {
                                console.log('Checkbox changed:', e.target.checked);
                                if (e.target.checked) {
                                    addSurfboardToCompare({ id: surfboard.key, ...surfboard });
                                } else {
                                    removeSurfboardFromCompare(surfboard.key);
                                }
                            }}
                        /> Compare
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default SearchSurfboards;
